<template>
  <div id="swagger-ui"></div>
</template>

<script>
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";
import { json } from "api/swagger";

export default {
  async mounted() {
    let spec = await json();

    SwaggerUI({
      spec,
      dom_id: "#swagger-ui"
    });
  }
};
</script>

<style></style>
